import { nanoid } from 'nanoid';
import create from 'zustand';
import { IBasketLine, ICalculatedBasket, IGetLoyaltyPointsResponse, IStoreInformation } from '$models';
import { http } from '$lib/http';
import { API_URL } from '$lib/helpers';
import { trackBasketEvent } from '$services/raptor.service';
import { getGtmProduct, trackAddToBasket } from '$services/gtm.service';
import { useSite } from './site';

const basketUrl = `${API_URL}/scom/api/basket`;

const memberPointsUrl = (basketId: string, membershipNumber: string) =>
    `${API_URL}/scom/api/basket/${basketId}/usePoints/${membershipNumber}`;

type TempBasketTypes = 'pickup' | 'express' | 'regular';

type BasketState = {
    basket?: ICalculatedBasket;
    tempBasketType?: TempBasketTypes;
    selectedStore?: IStoreInformation;
    selectedDeliveryZipCode?: string;
    updatingBasket?: boolean;
    updatingVoucher?: boolean;
    basketId?: string;
    shouldOpenMiniBasket?: boolean;
    memberPoints?: IGetLoyaltyPointsResponse;
    getBasket: (basketId: string) => void;
    createRegularBasket: (itemNumber?: string, zipcode?: string) => Promise<boolean>;
    createPickupBasket: (itemNumber?: string, store?: IStoreInformation) => Promise<boolean>;
    createExpressBasket: (itemNumber?: string, zipcode?: string, store?: IStoreInformation) => Promise<boolean>;
    onBasketError: (errorTitle?: string, errorMessage?: string, unavoidableError?: boolean) => void;
    setBasket: (basket?: ICalculatedBasket, type?: TempBasketTypes) => void;
    setShipping: (shippingKey: string) => void;
    addToBasket: (itemNumber?: string, quantity?: number, additive?: boolean) => Promise<boolean>;
    setClubMember: (isMember: boolean) => void;
    reset: () => void;
    addVoucher: (voucherCode: string) => Promise<void>;
    clearVoucher: (voucher?: string) => void;
    setUseMemberPoints: (membershipNumber: string, token?: string) => Promise<ICalculatedBasket>;
    getMemberPointsForBasket: (membershipNumber: string, token?: string) => Promise<IGetLoyaltyPointsResponse>;
};

/*
 * Temp basket used for quick checkout
 */

export const useTempBasket = create<BasketState>((set, get) => ({
    basket: undefined,
    basketId: undefined,
    updatingBasket: false,
    updatingVoucher: false,
    shouldOpenMiniBasket: false,
    selectedStore: undefined,
    memberPoints: undefined,
    getBasket: async (basketId?: string) => {
        const basketIdToFetch = basketId || get().basketId;
        if (!basketIdToFetch) {
            return;
        }
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket } = get();
        const basketResponse = await http(
            `${basketUrl}/${basketIdToFetch}?state=${Date.now()}`, // State to prevent cache on getBasket in browser
            undefined,
            true
        );
        if (!basketResponse.ok) {
            onBasketError();
            return;
        }
        const basket = await basketResponse.json();
        setBasket(basket);
        return basket;
    },
    createPickupBasket: async (itemNumber?: string, store?: IStoreInformation) => {
        const newBasketId = nanoid();
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket, addToBasket } = get();
        const basketResponse = await http(
            `${basketUrl}/${newBasketId}?state=${Date.now()}`, // State to prevent cache on getBasket in browser
            undefined,
            true
        );
        if (!basketResponse.ok) {
            onBasketError();
            return false;
        }
        set((state) => ({
            ...state,
            selectedStore: store,
            selectedDeliveryZipCode: undefined,
        }));
        const basket = await basketResponse.json();
        setBasket(basket, 'pickup');
        return await addToBasket(itemNumber, 1);
    },
    createExpressBasket: async (itemNumber?: string, zipcode?: string, store?: IStoreInformation) => {
        const newBasketId = nanoid();
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket, addToBasket } = get();
        const basketResponse = await http(
            `${basketUrl}/${newBasketId}?state=${Date.now()}`, // State to prevent cache on getBasket in browser
            undefined,
            true
        );
        if (!basketResponse.ok) {
            onBasketError();
            return false;
        }
        set((state) => ({
            ...state,
            selectedStore: store,
            selectedDeliveryZipCode: zipcode,
        }));
        const basket = await basketResponse.json();
        setBasket(basket, 'express');
        return await addToBasket(itemNumber, 1);
    },
    createRegularBasket: async (itemNumber?: string, zipcode?: string, store?: IStoreInformation) => {
        const newBasketId = nanoid();
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket, addToBasket } = get();
        const basketResponse = await http(
            `${basketUrl}/${newBasketId}?state=${Date.now()}`, // State to prevent cache on getBasket in browser
            undefined,
            true
        );
        if (!basketResponse.ok) {
            onBasketError();
            return false;
        }
        set((state) => ({
            ...state,
            selectedStore: store,
            selectedDeliveryZipCode: zipcode,
        }));
        const basket = await basketResponse.json();
        setBasket(basket, 'regular');
        return await addToBasket(itemNumber, 1);
    },
    onBasketError: async (errorTitle?: string, errorMessage?: string, unavoidableError?: boolean) => {
        useSite
            ?.getState()
            ?.pushGlobalNotification(
                errorTitle ? errorTitle : 'checkout.checkoutBasket.basketModalErrorHeader',
                errorMessage ? errorMessage : 'checkout.checkoutBasket.basketModelErrorGenericMessage',
                unavoidableError ? 'unavoidable' : 'error'
            );

        set((state) => ({
            ...state,
            updatingBasket: false,
            updatingVoucher: false,
        }));
    },
    setBasket: async (basket?: ICalculatedBasket, type?: TempBasketTypes) => {
        if (basket) {
            set((state) => ({
                ...state,
                updatingBasket: false,
                updatingVoucher: false,
                basketId: basket.id,
                basket: basket,
                ...(type && {
                    tempBasketType: type,
                }),
            }));
        }
    },
    addToBasket: async (itemNumber?: string, quantity?: number) => {
        if (itemNumber && quantity) {
            set((state) => ({
                ...state,
                updatingBasket: true,
            }));
            const { onBasketError, setBasket, basketId, basket: basketState } = get();
            const basketResponse = await http(
                `${basketUrl}/${basketId}/${itemNumber}?quantity=${
                    (basketState?.lines?.find((line) => line.product?.itemNumber === itemNumber && !line.isGiftShopItem)
                        ?.quantity || 0) + quantity
                }`,
                {
                    method: 'POST',
                },
                true
            );
            if (!basketResponse.ok) {
                onBasketError();
                return false;
            }
            const basket = await basketResponse.json();
            if (basket) {
                setBasket(basket);
                set((state) => ({
                    ...state,
                    shouldOpenMiniBasket: true,
                }));

                //Raptor tracking
                const basketLine = basket?.lines?.find((line: IBasketLine) => line?.product?.itemNumber === itemNumber);
                trackBasketEvent(basketLine, basket);

                //GTM tracking
                const gtmProduct = getGtmProduct(basketLine);
                if (gtmProduct) {
                    trackAddToBasket('DKK', [gtmProduct]);
                }
                return true;
            }
            return false;
        }
        return false;
    },
    setShipping: async (key: string) => {
        if (key) {
            set((state) => ({
                ...state,
                updatingBasket: true,
            }));
            const { onBasketError, setBasket, basketId } = get();
            const basketResponse = await http(
                `${basketUrl}/${basketId}/shipping?shippingKey=${key}`,
                {
                    method: 'POST',
                },
                true
            );
            if (!basketResponse.ok) {
                onBasketError();
                return;
            }
            const basket = await basketResponse.json();
            if (basket) {
                setBasket(basket);
            }
        }
    },
    setClubMember: async (isMember: boolean) => {
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket, basketId } = get();
        const basketResponse = await http(
            `${basketUrl}/${basketId}/clubMember?isClubMember=${isMember}`,
            {
                method: 'POST',
            },
            true
        );
        if (!basketResponse.ok) {
            onBasketError();
            return;
        }
        const basket = await basketResponse.json();
        if (basket) {
            setBasket(basket);
        }
    },
    reset: async () => {
        set((state) => ({
            ...state,
            updatingBasket: false,
            basket: undefined,
            basketId: undefined,
            tempBasketType: undefined,
            selectedStore: undefined,
            selectedDeliveryZipCode: undefined,
        }));
    },
    setUseMemberPoints: async (membershipNumber: string, token?: string) => {
        set((state) => ({
            ...state,
            updatingBasket: true,
        }));
        const { onBasketError, setBasket, basketId } = get();

        if (!basketId) {
            onBasketError();
            return;
        }

        const headers: RequestInit['headers'] = {};

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const basketResponse = await http(
            memberPointsUrl(basketId, membershipNumber), // State to prevent cache on getBasket in browser
            { method: 'POST', headers },
            true
        );
        if (!basketResponse.ok) {
            onBasketError();

            if (!basketId) {
                set((state) => ({
                    ...state,
                }));
            }

            return;
        }
        const basket = await basketResponse.json();
        setBasket(basket);
        return basket;
    },
    addVoucher: async (voucherCode: string) => {
        set((state) => ({
            ...state,
            updatingVoucher: true,
        }));
        const { onBasketError, setBasket, basketId } = get();
        const voucherResponse = await http(
            `${basketUrl}/${basketId}/voucher/${voucherCode}`,
            {
                method: 'POST',
            },
            true
        );

        if (!voucherResponse.ok) {
            switch ((await voucherResponse.json()).message) {
                case 'voucherSkippedNoEffect':
                    onBasketError('checkout.voucher.voucherErrorTitle', 'checkout.voucher.voucherNoEffect', true);
                    break;
                case 'voucherErrorPointsNotAllowed':
                    onBasketError(
                        'checkout.voucher.voucherErrorTitle',
                        'checkout.voucher.voucherErrorPointsNotAllowed',
                        true
                    );
                    break;
                case 'voucherOnlyOneAllowed':
                    onBasketError(
                        'checkout.voucher.voucherOnlyOneAllowedHeader',
                        'checkout.voucher.voucherOnlyOneAllowed',
                        true
                    );
                    break;
                default:
                    onBasketError('checkout.voucher.voucherErrorTitle', 'checkout.voucher.voucherErrorMessage', true);
            }
            return;
        }

        const basket = await voucherResponse.json();
        setBasket(basket);
    },
    clearVoucher: async (voucherCode?: string) => {
        set((state) => ({
            ...state,
            updatingVoucher: true,
        }));
        const { onBasketError, setBasket, basketId } = get();
        const voucherResponse = await http(
            `${basketUrl}/${basketId}/voucher/${voucherCode}`,
            {
                method: 'DELETE',
            },
            true
        );

        if (!voucherResponse.ok) {
            onBasketError();
            return;
        }

        const basket = await voucherResponse.json();
        setBasket(basket);
    },
    getMemberPointsForBasket: async (membershipNumber: string, token?: string) => {
        const points = await http(
            `${API_URL}/scom/api/loyalty/${membershipNumber}/getpoints?basketid=${get().basketId}`,
            {
                method: 'GET',
                headers: token
                    ? {
                          Authorization: `Bearer ${token}`,
                      }
                    : undefined,
            },
            true
        ).then((points) => points.json() as IGetLoyaltyPointsResponse);

        set((state) => ({
            ...state,
            memberPoints: points,
        }));
        return points;
    },
}));
