import create from 'zustand';
import { IStoreInformation } from '$models';
import { getAllGlobalStores, getAllStores } from '$services/store.service';

type StoresState = {
    stores?: IStoreInformation[];
    globalStores?: IStoreInformation[]; // Stores under both brands
    isLoading?: boolean;
    getStores: () => void;
    getGlobalStores: () => void;
};

export const useStores = create<StoresState>((set, get) => ({
    stores: undefined,
    globalStores: undefined,
    isLoading: false,
    getStores: async () => {
        if (!get().stores) {
            set((state) => ({
                ...state,
                isLoading: true,
            }));
            const stores = await getAllStores();
            if (stores) {
                set((state) => ({
                    ...state,
                    stores,
                    isLoading: false,
                }));
            } else {
                set((state) => ({
                    ...state,
                    isLoading: false,
                }));
            }
        }
    },
    getGlobalStores: async () => {
        if (!get().globalStores) {
            set((state) => ({
                ...state,
                isLoading: true,
            }));
            const globalStores = await getAllGlobalStores();
            if (globalStores) {
                set((state) => ({
                    ...state,
                    globalStores,
                    isLoading: false,
                }));
            } else {
                set((state) => ({
                    ...state,
                    isLoading: false,
                }));
            }
        }
    },
}));
